<template>
  <product :title="title"
    :swiper-data="swiperData"
    :slogan="slogan"
    :price="price"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    :video="video"
    buy-link="https://j.youzan.com/ECG1Kh"
    is-switch-title
    is-switch-slogan
    is-switch-price
    is-bus-city1
  ></product>
</template>

<script>
import { CDN_URL, RELATIVED_PRODUCTS } from '@/config'
import Product from '../Product'

export default {
  name: 'gtr',
  components: {
    Product
  },
  data () {
    return {
      title: [
        'Amazfit GTR  47mm',
        'Amazfit GTR  47mm',
        'Amazfit GTR  47mm 钛金属',
        'Amazfit GTR  42mm',
        'Amazfit GTR  42mm',
        'Amazfit GTR  42mm',
        'Amazfit GTR  42mm'
      ],
      slogan: [
        '金属陶瓷奢华工艺 | 视网膜级绚丽显示屏·全天候显示 | 百款个性表盘<br/> 强劲续航 | AI 自动甄别心律不齐（含房颤）| 12 种运动模式 <br/> 50 米防水 | 百城公交 | 模拟门卡',
        '金属陶瓷奢华工艺 | 视网膜级绚丽显示屏·全天候显示 | 百款个性表盘<br/> 强劲续航 | AI 自动甄别心律不齐（含房颤）| 12 种运动模式 <br/> 50 米防水 | 百城公交 | 模拟门卡',
        '金属陶瓷奢华工艺 | 高端钛金属表身 |  氟橡胶表带 <br/> 全天候显示视网膜屏幕 | 百款个性表盘 | 24 天强劲续航 <br/> AI 自动甄别心律不齐(含房颤) | 50 米防水 | 12 种运动模式<br/> 百城公交卡 | NFC 模拟门卡',
        '金属陶瓷奢华工艺 | 全天候显示视网膜屏幕 | 百款个性表盘<br/> 12 天强劲续航 | 12 种运动模式 | 50 米防水 | 百城公交卡<br/> NFC 模拟门卡',
        '金属陶瓷奢华工艺 | 全天候显示视网膜屏幕 | 百款个性表盘<br/> 12 天强劲续航 | 12 种运动模式 | 50 米防水 | 百城公交卡<br/> NFC 模拟门卡',
        '金属陶瓷奢华工艺 | 全天候显示视网膜屏幕 | 百款个性表盘<br/> 12 天强劲续航 | 12 种运动模式 | 50 米防水 | 百城公交卡<br/> NFC 模拟门卡',
        '金属陶瓷奢华工艺 | 全天候显示视网膜屏幕 | 百款个性表盘<br/> 12 天强劲续航 | 12 种运动模式 | 50 米防水 | 百城公交卡<br/> NFC 模拟门卡'
      ],
      price: [
        999,
        999,
        1399,
        799,
        799,
        799,
        799
      ],
      colors: [
        '铝合金',
        '不锈钢',
        '钛金属',
        '星空黑',
        '樱花粉',
        '月光白',
        '珊瑚红'
      ],
      video: {
        url: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/videos/gtr.mp4',
        poster: CDN_URL + '/images/poster/gtr.png'
      },
      swiperData: [
        [
          CDN_URL + '/images/product/gtr/swiper/1.png',
          CDN_URL + '/images/product/gtr/swiper/2.png',
          CDN_URL + '/images/product/gtr/swiper/3.png',
          CDN_URL + '/images/product/gtr/swiper/4.png'
        ],
        [
          CDN_URL + '/images/product/gtr/swiper/5.png',
          CDN_URL + '/images/product/gtr/swiper/6.png',
          CDN_URL + '/images/product/gtr/swiper/7.png',
          CDN_URL + '/images/product/gtr/swiper/8.png'
        ],
        [
          CDN_URL + '/images/product/gtr/swiper/9.png',
          CDN_URL + '/images/product/gtr/swiper/10.png',
          CDN_URL + '/images/product/gtr/swiper/11.png',
          CDN_URL + '/images/product/gtr/swiper/12.png'
        ],
        [
          CDN_URL + '/images/product/gtr/swiper/13.png',
          CDN_URL + '/images/product/gtr/swiper/14.png',
          CDN_URL + '/images/product/gtr/swiper/15.png',
          CDN_URL + '/images/product/gtr/swiper/16.png'
        ],
        [
          CDN_URL + '/images/product/gtr/swiper/17.png',
          CDN_URL + '/images/product/gtr/swiper/18.png',
          CDN_URL + '/images/product/gtr/swiper/19.png',
          CDN_URL + '/images/product/gtr/swiper/20.png'
        ],
        [
          CDN_URL + '/images/product/gtr/swiper/21.png',
          CDN_URL + '/images/product/gtr/swiper/22.png'
        ],
        [
          CDN_URL + '/images/product/gtr/swiper/23.png',
          CDN_URL + '/images/product/gtr/swiper/24.png',
          CDN_URL + '/images/product/gtr/swiper/25.png',
          CDN_URL + '/images/product/gtr/swiper/26.png'
        ]
      ],
      relativedProducts: RELATIVED_PRODUCTS,
      detailImages: [
        CDN_URL + '/images/product/gtr/detail/q01.jpg',
        CDN_URL + '/images/product/gtr/detail/q02.jpg',
        CDN_URL + '/images/product/gtr/detail/s03.jpg',
        CDN_URL + '/images/product/gtr/detail/s04.jpg',
        CDN_URL + '/images/product/gtr/detail/s05.jpg',
        CDN_URL + '/images/product/gtr/detail/s06.jpg',
        CDN_URL + '/images/product/gtr/detail/s07.jpg',
        CDN_URL + '/images/product/gtr/detail/s08.jpg',
        CDN_URL + '/images/product/gtr/detail/s09.jpg',
        CDN_URL + '/images/product/gtr/detail/s10_1.jpg',
        CDN_URL + '/images/product/gtr/detail/s11.jpg',
        CDN_URL + '/images/product/gtr/detail/s12.jpg',
        CDN_URL + '/images/product/gtr/detail/s13.jpg',
        CDN_URL + '/images/product/gtr/detail/s14.jpg',
        CDN_URL + '/images/common/zepp-black.jpg',
        CDN_URL + '/images/product/gtr/detail/s16.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/gtr/detail/s17.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
